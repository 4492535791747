import { navigate, PageProps } from 'gatsby';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import CustomerForm from '../../components/CustomerForm';
import Layout from '../../components/Layout';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { request } from '../../utils/request';
import { parseCustomerName } from '../../utils/parseCustomerName';
import { CustomerBM } from '@bm-js/h2o-shared';
import { ActionType } from '../../types/dispatch.types';

const StyledEditCustomer = styled.div`
  .edit-customer-card {
    max-width: 1000px;
  }
`;

const EditCustomer = (
  props: PageProps<object, object, { formData?: CustomerBM }>
) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const handleSubmit = async (formData: Partial<CustomerBM>) => {
    const locationCustomerName =
      props.location.state.formData?.customerInformation.name;
    const changeDoName =
      locationCustomerName !== formData.customerInformation?.name;

    let errors = [];
    try {
      const { err } = await request({
        state,
        dispatch,
        path: `customers/update/${formData._id}`,
        method: 'POST',
        body: formData,
        successText: 'Uppdaterad',
      });
      if (err) errors.push(err);

      if (changeDoName) {
        const { err } = await request({
          state,
          dispatch,
          path: `files/{token}/change-customer-name/${parseCustomerName(
            locationCustomerName
          )}/${parseCustomerName(formData)}`,
          isDoServer: true,
          successText: 'Uppdaterad',
          method: 'POST',
        });
        if (err) errors.push(err);
      }
      if (errors.length) throw errors;
      navigate(`/customers/single/?id=${formData._id}`);
    } catch (e) {
      console.error(e);
    }
  };

  const crumbs = useMemo(() => {
    const {
      location: { state },
    } = props;
    const lastCrumb = state
      ? `Redigera ${state.formData?.customerInformation.name}`
      : undefined;
    return [
      { to: '/home', p: 'Hem' },
      { to: '/admin', p: 'Admin' },
      { p: lastCrumb },
    ];
  }, [props]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_PREVENT_NAVIGATE, value: true });
    return () => {
      dispatch({ type: ActionType.SET_PREVENT_NAVIGATE, value: false });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <h1 className="page-header">Redigera kund</h1>
      <Breadcrumbs crumbs={crumbs} />
      <StyledEditCustomer>
        <Card className="edit-customer-card">
          <CustomerForm
            handleSubmit={handleSubmit}
            formData={props.location.state?.formData}
          />
        </Card>
      </StyledEditCustomer>
    </Layout>
  );
};

export default EditCustomer;
